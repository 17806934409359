import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import {
  Tab,
  Box,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  Tooltip,
  Button,
  Chip,
} from "@mui/material";
import NumberFormatCustom from './components/customNumberFormat'
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Sidebar from "./components/sidebar";
import Header from "./components/header"
import DetailsCopy from "./components/details-copy";
import Footer from "./components/footer";
import { StaticImage } from "gatsby-plugin-image";
import { Link, animateScroll as scroll } from "react-scroll";
import useDetectPrint from "react-detect-print";
import "../styles/main.scss";
import IconExclamation from '../images/icon-exclamation.svg';

import { StyledEngineProvider } from "@mui/material/styles";

// Data load
import { ratingRegionsData } from "../data/rating-regions";
import { zipCodesData } from "../data/zip-codes";
import { ageBandsData } from "../data/age-bands";
import { medicaidExpansionData } from "../data/medicaid-expansion";
import { aptcSubsidyData } from "../data/aptc-subsidy";
import { fplData } from "../data/fpl";
import { planfinderData } from "../data/planfinder";
import { ConstructionOutlined, FlashlightOnTwoTone, FormatColorResetSharp } from "@mui/icons-material";
import InstructionalBlock from "./components/instructional-block";
import StatPanel from "./components/stat-panel";

export default function IndexPage(props) {
  // Declare all our variables, constants, and set state
  const [showCalculatorResults, setShowCalculatorResults] = useState(false);
  const [hideCalculator, setHideCalculator] = useState(true);
  const [showCalcResultsButton, setShowCalcResultsButton] = useState(false)
  const [hideCalcResultsButton, setHideCalcResultsButton] = useState(true)
  const [showFplExclusionNote, setShowFplExclusionNote] = useState(false)
  const [showFplNYNote, setShowFplNYNote] = useState(false)
  const [showFplMNNote, setShowFplMNNote] = useState(false)
  const [showMedicaidExclusionNote, setShowMedicaidExclusionNote] = useState(false)
  const [value, setValue] = React.useState("2");
  const [householdMemberCount, setHouseholdMemberCount] = React.useState(0);
  const [zipCode, setZipCode] = useState("");
  const [districtOrRegion, setDistrictOrRegion] = useState("RatingRegion");
  const [householdIncome, setHouseholdIncome] = useState("");
  const [householdIncomeString, setHouseholdIncomeString] = useState("");
  const [ageMemberOne, setAgeMemberOne] = useState("");
  const [ageMemberTwo, setAgeMemberTwo] = useState("");
  const [ageMemberThree, setAgeMemberThree] = useState("");
  const [ageMemberFour, setAgeMemberFour] = useState("");
  const [ageMemberFive, setAgeMemberFive] = useState("");
  const [ageMemberSix, setAgeMemberSix] = useState("");
  const [ageMemberSeven, setAgeMemberSeven] = useState("");
  const [showZipcodeComma, setShowZipcodeComma] = useState(false);

  // Show/hide calculated results
  const calcResultsVisibleClass = "calculated-results-are-visible"

  // Initial unset values
  let acaSubsidyAmountInitial = ""
  let arpaSubsidyAmountInitial = ""
  let acaMonthlyNetPremiumLcSilverInitial = ""
  let acaMonthlyNetPremiumSilverBenchmarkInitial = ""
  let arpaMonthlyNetPremiumLcSilverInitial = ""
  let arpaMonthlyNetPremiumSilverBenchmarkInitial = ""
  let annualSubsidyAcaInitial = ""
  let annualSubsidyArpaInitial = ""
  let silverBenchmarkMonthlyPremium = ""
  let lcSilverMonthlyPremium = ""
  let lcSilverAnnualPremium = ""
  let ageMultiplierMemberOne = ""
  let ageMultiplierMemberTwo = ""
  let ageMultiplierMemberThree = ""
  let ageMultiplierMemberFour = ""
  let ageMultiplierMemberFive = ""
  let ageMultiplierMemberSix = ""
  let ageMultiplierMemberSeven = ""
  let arpaMonthlyNetPremiumSilverBenchmark = ""
  let arpaMonthlyNetPremiumSilverBenchmarkRounded = ""
  let arpaSubsidyAmountRounded = ""

  const householdMemberIncrementMinus = () => {
    if (householdMemberCount > 0 ) {
      setHouseholdMemberCount(
        householdMemberCount - 1
      );
    }
  };

  const householdMemberIncrementPlus = () => {
    if (householdMemberCount < 7 ) {
      setHouseholdMemberCount(
        householdMemberCount + 1
      );
    }
  };

  function showCalculatedResults(e) {
    document.body.classList.add(calcResultsVisibleClass);
  }

  useEffect(() => {
    // Update the body classList using the browser API
    document.body.classList = `has-${householdMemberCount}-household-members`;
  });

  // User input functions in form
  function handleZipCodeInputChange(e) {
    if (e.target.value.length < 6) {
      setZipCode(e.target.value);
    }
  }

  // Planfinder data loops (Plan URL and Plan Name)
  let planfinderUrlInitialOne = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Url);
  let planfinderUrlOne = planfinderUrlInitialOne[0];

  let planfinderUrlInitialTwo = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Url);
  let planfinderUrlTwo = planfinderUrlInitialTwo[1];

  let planfinderUrlInitialThree = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Url);
  let planfinderUrlThree = planfinderUrlInitialThree[2];

  let planfinderUrlInitialFour = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Url);
  let planfinderUrlFour = planfinderUrlInitialFour[3];

  let planfinderUrlInitialFive = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Url);
  let planfinderUrlFive = planfinderUrlInitialFive[4];

  let planfinderNameInitialOne = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Pn);
  let planNameOne = planfinderNameInitialOne[0];

  let planfinderNameInitialTwo = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Pn);
  let planNameTwo = planfinderNameInitialTwo[1];

  let planfinderNameInitialThree = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Pn);
  let planNameThree = planfinderNameInitialThree[2];

  let planfinderNameInitialFour = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Pn);
  let planNameFour = planfinderNameInitialFour[3];

  let planfinderNameInitialFive = planfinderData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Pn);
  let planNameFive = planfinderNameInitialFive[4];

  // Find Zip Code that matches user input from Zip Code field
  let zipCodeRatingRegionInitial = zipCodesData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.Rr);
  let zipCodeRatingRegion = zipCodeRatingRegionInitial.toString();

  let zipCodeCounty = zipCodesData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.County);

  let zipCodeState = zipCodesData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.State);

  let zipCodeStateAbbrevInitial = zipCodesData
  .filter((item) => item.Zc === zipCode)
  .map((filteredItem) => filteredItem.StAb);
  let zipCodeStateAbbrev = zipCodeStateAbbrevInitial.toString();

  // Determine if this state has Medicaid expansion
  let isMedicaidExpandedInitial = medicaidExpansionData
  .filter((item) => item.StateAbbrev === zipCodeStateAbbrev)
  .map((filteredItem) => filteredItem.IsMedicaidExpanded);
  let isMedicaidExpanded = isMedicaidExpandedInitial.toString();

  // Get the Silver Premium value based on selected Rating Region
  let ratingRegionSilverPremium = ratingRegionsData
    .filter((item) => item.RatingRegion === zipCodeRatingRegion)
    .map((filteredItem) => filteredItem.BenchmarkSing21);
  // Get the LC Silver value based on selected Rating Region
  let ratingRegionLcSilver = ratingRegionsData
    .filter((item) => item.RatingRegion === zipCodeRatingRegion)
    .map((filteredItem) => filteredItem.LCSILVER);

  // Get age multiplier for household member 1
  let ageMultiplierMemberOneDefault = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.Default);
  let ageMultiplierMemberOneAL = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.AL);
  let ageMultiplierMemberOneDC = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.DC);
  let ageMultiplierMemberOneMA = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.MA);
  let ageMultiplierMemberOneMN = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.MN);
  let ageMultiplierMemberOneMS = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.MS);
  let ageMultiplierMemberOneNJ = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.NJ);
  let ageMultiplierMemberOneNY = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.NY);
  let ageMultiplierMemberOneOR = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.OR);
  let ageMultiplierMemberOneVT = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.VT);
  let ageMultiplierMemberOneUT = ageBandsData
    .filter((item) => item.Age === ageMemberOne)
    .map((filteredItem) => filteredItem.UT);

  if (zipCodeStateAbbrev == "AL") {
    ageMultiplierMemberOne = ageMultiplierMemberOneAL
  }
  if (zipCodeStateAbbrev == "DC") {
    ageMultiplierMemberOne = ageMultiplierMemberOneDC
  }
  if (zipCodeStateAbbrev == "MA") {
    ageMultiplierMemberOne = ageMultiplierMemberOneMA
  }
  if (zipCodeStateAbbrev == "MN") {
    ageMultiplierMemberOne = ageMultiplierMemberOneMN
  }
  if (zipCodeStateAbbrev == "MS") {
    ageMultiplierMemberOne = ageMultiplierMemberOneMS
  }
  if (zipCodeStateAbbrev == "NJ") {
    ageMultiplierMemberOne = ageMultiplierMemberOneNJ
  }
  if (zipCodeStateAbbrev == "NY") {
    ageMultiplierMemberOne = ageMultiplierMemberOneNY
  }
  if (zipCodeStateAbbrev == "OR") {
    ageMultiplierMemberOne = ageMultiplierMemberOneOR
  }
  if (zipCodeStateAbbrev == "VT") {
    ageMultiplierMemberOne = ageMultiplierMemberOneVT
  }
  if (zipCodeStateAbbrev == "UT") {
    ageMultiplierMemberOne = ageMultiplierMemberOneUT
  }
  if (zipCodeStateAbbrev !== "AL" && zipCodeStateAbbrev !== "DC" && zipCodeStateAbbrev !== "MA" && zipCodeStateAbbrev !== "MN" && zipCodeStateAbbrev !== "MS" && zipCodeStateAbbrev !== "NJ" && zipCodeStateAbbrev !== "NY" && zipCodeStateAbbrev !== "OR" && zipCodeStateAbbrev !== "VT" && zipCodeStateAbbrev !== "UT") {
    ageMultiplierMemberOne = ageMultiplierMemberOneDefault
  }


  // Get age multiplier for household member 2
  let ageMultiplierMemberTwoDefault = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.Default);
  let ageMultiplierMemberTwoAL = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.AL);
  let ageMultiplierMemberTwoDC = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.DC);
  let ageMultiplierMemberTwoMA = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.MA);
  let ageMultiplierMemberTwoMN = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.MN);
  let ageMultiplierMemberTwoMS = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.MS);
  let ageMultiplierMemberTwoNJ = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.NJ);
  let ageMultiplierMemberTwoNY = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.NY);
  let ageMultiplierMemberTwoOR = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.OR);
  let ageMultiplierMemberTwoVT = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.VT);
  let ageMultiplierMemberTwoUT = ageBandsData
    .filter((item) => item.Age === ageMemberTwo)
    .map((filteredItem) => filteredItem.UT);

  if (zipCodeStateAbbrev == "AL") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoAL
  }
  if (zipCodeStateAbbrev == "DC") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoDC
  }
  if (zipCodeStateAbbrev == "MA") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoMA
  }
  if (zipCodeStateAbbrev == "MN") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoMN
  }
  if (zipCodeStateAbbrev == "MS") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoMS
  }
  if (zipCodeStateAbbrev == "NJ") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoNJ
  }
  if (zipCodeStateAbbrev == "NY") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoNY
  }
  if (zipCodeStateAbbrev == "OR") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoOR
  }
  if (zipCodeStateAbbrev == "VT") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoVT
  }
  if (zipCodeStateAbbrev == "UT") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoUT
  }
  if (zipCodeStateAbbrev !== "AL" && zipCodeStateAbbrev !== "DC" && zipCodeStateAbbrev !== "MA" && zipCodeStateAbbrev !== "MN" && zipCodeStateAbbrev !== "MS" && zipCodeStateAbbrev !== "NJ" && zipCodeStateAbbrev !== "NY" && zipCodeStateAbbrev !== "OR" && zipCodeStateAbbrev !== "VT" && zipCodeStateAbbrev !== "UT") {
    ageMultiplierMemberTwo = ageMultiplierMemberTwoDefault
  }

  // Get age multiplier for household member 3
  let ageMultiplierMemberThreeDefault = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.Default);
  let ageMultiplierMemberThreeAL = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.AL);
  let ageMultiplierMemberThreeDC = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.DC);
  let ageMultiplierMemberThreeMA = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.MA);
  let ageMultiplierMemberThreeMN = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.MN);
  let ageMultiplierMemberThreeMS = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.MS);
  let ageMultiplierMemberThreeNJ = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.NJ);
  let ageMultiplierMemberThreeNY = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.NY);
  let ageMultiplierMemberThreeOR = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.OR);
  let ageMultiplierMemberThreeVT = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.VT);
  let ageMultiplierMemberThreeUT = ageBandsData
    .filter((item) => item.Age === ageMemberThree)
    .map((filteredItem) => filteredItem.UT);

  if (zipCodeStateAbbrev == "AL") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeAL
  }
  if (zipCodeStateAbbrev == "DC") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeDC
  }
  if (zipCodeStateAbbrev == "MA") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeMA
  }
  if (zipCodeStateAbbrev == "MN") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeMN
  }
  if (zipCodeStateAbbrev == "MS") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeMS
  }
  if (zipCodeStateAbbrev == "NJ") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeNJ
  }
  if (zipCodeStateAbbrev == "NY") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeNY
  }
  if (zipCodeStateAbbrev == "OR") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeOR
  }
  if (zipCodeStateAbbrev == "VT") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeVT
  }
  if (zipCodeStateAbbrev == "UT") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeUT
  }
  if (zipCodeStateAbbrev !== "AL" && zipCodeStateAbbrev !== "DC" && zipCodeStateAbbrev !== "MA" && zipCodeStateAbbrev !== "MN" && zipCodeStateAbbrev !== "MS" && zipCodeStateAbbrev !== "NJ" && zipCodeStateAbbrev !== "NY" && zipCodeStateAbbrev !== "OR" && zipCodeStateAbbrev !== "VT" && zipCodeStateAbbrev !== "UT") {
    ageMultiplierMemberThree = ageMultiplierMemberThreeDefault
  }

  // Get age multiplier for household member 4
  let ageMultiplierMemberFourDefault = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.Default);
  let ageMultiplierMemberFourAL = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.AL);
  let ageMultiplierMemberFourDC = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.DC);
  let ageMultiplierMemberFourMA = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.MA);
  let ageMultiplierMemberFourMN = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.MN);
  let ageMultiplierMemberFourMS = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.MS);
  let ageMultiplierMemberFourNJ = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.NJ);
  let ageMultiplierMemberFourNY = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.NY);
  let ageMultiplierMemberFourOR = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.OR);
  let ageMultiplierMemberFourVT = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.VT);
  let ageMultiplierMemberFourUT = ageBandsData
    .filter((item) => item.Age === ageMemberFour)
    .map((filteredItem) => filteredItem.UT);

  if (zipCodeStateAbbrev == "AL") {
    ageMultiplierMemberFour = ageMultiplierMemberFourAL
  }
  if (zipCodeStateAbbrev == "DC") {
    ageMultiplierMemberFour = ageMultiplierMemberFourDC
  }
  if (zipCodeStateAbbrev == "MA") {
    ageMultiplierMemberFour = ageMultiplierMemberFourMA
  }
  if (zipCodeStateAbbrev == "MN") {
    ageMultiplierMemberFour = ageMultiplierMemberFourMN
  }
  if (zipCodeStateAbbrev == "MS") {
    ageMultiplierMemberFour = ageMultiplierMemberFourMS
  }
  if (zipCodeStateAbbrev == "NJ") {
    ageMultiplierMemberFour = ageMultiplierMemberFourNJ
  }
  if (zipCodeStateAbbrev == "NY") {
    ageMultiplierMemberFour = ageMultiplierMemberFourNY
  }
  if (zipCodeStateAbbrev == "OR") {
    ageMultiplierMemberFour = ageMultiplierMemberFourOR
  }
  if (zipCodeStateAbbrev == "VT") {
    ageMultiplierMemberFour = ageMultiplierMemberFourVT
  }
  if (zipCodeStateAbbrev == "UT") {
    ageMultiplierMemberFour = ageMultiplierMemberFourUT
  }
  if (zipCodeStateAbbrev !== "AL" && zipCodeStateAbbrev !== "DC" && zipCodeStateAbbrev !== "MA" && zipCodeStateAbbrev !== "MN" && zipCodeStateAbbrev !== "MS" && zipCodeStateAbbrev !== "NJ" && zipCodeStateAbbrev !== "NY" && zipCodeStateAbbrev !== "OR" && zipCodeStateAbbrev !== "VT" && zipCodeStateAbbrev !== "UT") {
    ageMultiplierMemberFour = ageMultiplierMemberFourDefault
  }

  // Get age multiplier for household member 5
  let ageMultiplierMemberFiveDefault = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.Default);
  let ageMultiplierMemberFiveAL = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.AL);
  let ageMultiplierMemberFiveDC = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.DC);
  let ageMultiplierMemberFiveMA = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.MA);
  let ageMultiplierMemberFiveMN = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.MN);
  let ageMultiplierMemberFiveMS = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.MS);
  let ageMultiplierMemberFiveNJ = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.NJ);
  let ageMultiplierMemberFiveNY = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.NY);
  let ageMultiplierMemberFiveOR = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.OR);
  let ageMultiplierMemberFiveVT = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.VT);
  let ageMultiplierMemberFiveUT = ageBandsData
    .filter((item) => item.Age === ageMemberFive)
    .map((filteredItem) => filteredItem.UT);

  if (zipCodeStateAbbrev == "AL") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveAL
  }
  if (zipCodeStateAbbrev == "DC") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveDC
  }
  if (zipCodeStateAbbrev == "MA") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveMA
  }
  if (zipCodeStateAbbrev == "MN") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveMN
  }
  if (zipCodeStateAbbrev == "MS") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveMS
  }
  if (zipCodeStateAbbrev == "NJ") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveNJ
  }
  if (zipCodeStateAbbrev == "NY") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveNY
  }
  if (zipCodeStateAbbrev == "OR") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveOR
  }
  if (zipCodeStateAbbrev == "VT") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveVT
  }
  if (zipCodeStateAbbrev == "UT") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveUT
  }
  if (zipCodeStateAbbrev !== "AL" && zipCodeStateAbbrev !== "DC" && zipCodeStateAbbrev !== "MA" && zipCodeStateAbbrev !== "MN" && zipCodeStateAbbrev !== "MS" && zipCodeStateAbbrev !== "NJ" && zipCodeStateAbbrev !== "NY" && zipCodeStateAbbrev !== "OR" && zipCodeStateAbbrev !== "VT" && zipCodeStateAbbrev !== "UT") {
    ageMultiplierMemberFive = ageMultiplierMemberFiveDefault
  }

  // Get age multiplier for household member 6
  let ageMultiplierMemberSixDefault = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.Default);
  let ageMultiplierMemberSixAL = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.AL);
  let ageMultiplierMemberSixDC = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.DC);
  let ageMultiplierMemberSixMA = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.MA);
  let ageMultiplierMemberSixMN = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.MN);
  let ageMultiplierMemberSixMS = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.MS);
  let ageMultiplierMemberSixNJ = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.NJ);
  let ageMultiplierMemberSixNY = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.NY);
  let ageMultiplierMemberSixOR = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.OR);
  let ageMultiplierMemberSixVT = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.VT);
  let ageMultiplierMemberSixUT = ageBandsData
      .filter((item) => item.Age === ageMemberSix)
      .map((filteredItem) => filteredItem.UT);

  if (zipCodeStateAbbrev == "AL") {
    ageMultiplierMemberSix = ageMultiplierMemberSixAL
  }
  if (zipCodeStateAbbrev == "DC") {
    ageMultiplierMemberSix = ageMultiplierMemberSixDC
  }
  if (zipCodeStateAbbrev == "MA") {
    ageMultiplierMemberSix = ageMultiplierMemberSixMA
  }
  if (zipCodeStateAbbrev == "MN") {
    ageMultiplierMemberSix = ageMultiplierMemberSixMN
  }
  if (zipCodeStateAbbrev == "MS") {
    ageMultiplierMemberSix = ageMultiplierMemberSixMS
  }
  if (zipCodeStateAbbrev == "NJ") {
    ageMultiplierMemberSix = ageMultiplierMemberSixNJ
  }
  if (zipCodeStateAbbrev == "NY") {
    ageMultiplierMemberSix = ageMultiplierMemberSixNY
  }
  if (zipCodeStateAbbrev == "OR") {
    ageMultiplierMemberSix = ageMultiplierMemberSixOR
  }
  if (zipCodeStateAbbrev == "VT") {
    ageMultiplierMemberSix = ageMultiplierMemberSixVT
  }
  if (zipCodeStateAbbrev == "UT") {
    ageMultiplierMemberSix = ageMultiplierMemberSixUT
  }
  if (zipCodeStateAbbrev !== "AL" && zipCodeStateAbbrev !== "DC" && zipCodeStateAbbrev !== "MA" && zipCodeStateAbbrev !== "MN" && zipCodeStateAbbrev !== "MS" && zipCodeStateAbbrev !== "NJ" && zipCodeStateAbbrev !== "NY" && zipCodeStateAbbrev !== "OR" && zipCodeStateAbbrev !== "VT" && zipCodeStateAbbrev !== "UT") {
    ageMultiplierMemberSix = ageMultiplierMemberSixDefault
  }

  // Get age multiplier for household member 7
  let ageMultiplierMemberSevenDefault = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.Default);
  let ageMultiplierMemberSevenAL = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.AL);
  let ageMultiplierMemberSevenDC = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.DC);
  let ageMultiplierMemberSevenMA = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.MA);
  let ageMultiplierMemberSevenMN = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.MN);
  let ageMultiplierMemberSevenMS = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.MS);
  let ageMultiplierMemberSevenNJ = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.NJ);
  let ageMultiplierMemberSevenNY = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.NY);
  let ageMultiplierMemberSevenOR = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.OR);
  let ageMultiplierMemberSevenVT = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.VT);
  let ageMultiplierMemberSevenUT = ageBandsData
      .filter((item) => item.Age === ageMemberSeven)
      .map((filteredItem) => filteredItem.UT);

  if (zipCodeStateAbbrev == "AL") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenAL
  }
  if (zipCodeStateAbbrev == "DC") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenDC
  }
  if (zipCodeStateAbbrev == "MA") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenMA
  }
  if (zipCodeStateAbbrev == "MN") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenMN
  }
  if (zipCodeStateAbbrev == "MS") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenMS
  }
  if (zipCodeStateAbbrev == "NJ") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenNJ
  }
  if (zipCodeStateAbbrev == "NY") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenNY
  }
  if (zipCodeStateAbbrev == "OR") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenOR
  }
  if (zipCodeStateAbbrev == "VT") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenVT
  }
  if (zipCodeStateAbbrev == "UT") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenUT
  }
  if (zipCodeStateAbbrev !== "AL" && zipCodeStateAbbrev !== "DC" && zipCodeStateAbbrev !== "MA" && zipCodeStateAbbrev !== "MN" && zipCodeStateAbbrev !== "MS" && zipCodeStateAbbrev !== "NJ" && zipCodeStateAbbrev !== "NY" && zipCodeStateAbbrev !== "OR" && zipCodeStateAbbrev !== "VT" && zipCodeStateAbbrev !== "UT") {
    ageMultiplierMemberSeven = ageMultiplierMemberSevenDefault
  }

  // Get calculated Silver Premium Rating Region amount for household member 1
  let silverPremiumRatingRegionHouseholdMemberOne = (ratingRegionSilverPremium * ageMultiplierMemberOne)
  // Get calculated LC Silver Rating Region amount for household member 1
  let lcSilverRatingRegionHouseholdMemberOne = (ratingRegionLcSilver * ageMultiplierMemberOne)

  // Get calculated Silver Premium Rating Region amount for household member 2
  let silverPremiumRatingRegionHouseholdMemberTwo = (ratingRegionSilverPremium * ageMultiplierMemberTwo)
  // Get calculated LC Silver Rating Region amount for household member 2
  let lcSilverRatingRegionHouseholdMemberTwo = (ratingRegionLcSilver * ageMultiplierMemberTwo)

  // Get calculated Silver Premium Rating Region amount for household member 3
  let silverPremiumRatingRegionHouseholdMemberThree = (ratingRegionSilverPremium * ageMultiplierMemberThree)
  // Get calculated LC Silver Rating Region amount for household member 3
  let lcSilverRatingRegionHouseholdMemberThree = (ratingRegionLcSilver * ageMultiplierMemberThree)

  // Get calculated Silver Premium Rating Region amount for household member 4
  let silverPremiumRatingRegionHouseholdMemberFour = (ratingRegionSilverPremium * ageMultiplierMemberFour)
  // Get calculated LC Silver Rating Region amount for household member 4
  let lcSilverRatingRegionHouseholdMemberFour = (ratingRegionLcSilver * ageMultiplierMemberFour)

  // Get calculated Silver Premium Rating Region amount for household member 5
  let silverPremiumRatingRegionHouseholdMemberFive = (ratingRegionSilverPremium * ageMultiplierMemberFive)
  // Get calculated LC Silver Rating Region amount for household member 5
  let lcSilverRatingRegionHouseholdMemberFive = (ratingRegionLcSilver * ageMultiplierMemberFive)

  // Get calculated Silver Premium Rating Region amount for household member 6
  let silverPremiumRatingRegionHouseholdMemberSix = (ratingRegionSilverPremium * ageMultiplierMemberSix)
  // Get calculated LC Silver Rating Region amount for household member 6
  let lcSilverRatingRegionHouseholdMemberSix = (ratingRegionLcSilver * ageMultiplierMemberSix)

  // Get calculated Silver Premium Rating Region amount for household member 7
  let silverPremiumRatingRegionHouseholdMemberSeven = (ratingRegionSilverPremium * ageMultiplierMemberSeven)
  // Get calculated LC Silver Rating Region amount for household member 7
  let lcSilverRatingRegionHouseholdMemberSeven = (ratingRegionLcSilver * ageMultiplierMemberSeven)

  // Get Rating Region monthly premium total (Silver Benchmark)
  let silverPremiumRatingRegionMonthlyPremium =
    silverPremiumRatingRegionHouseholdMemberOne +
    silverPremiumRatingRegionHouseholdMemberTwo +
    silverPremiumRatingRegionHouseholdMemberThree +
    silverPremiumRatingRegionHouseholdMemberFour +
    silverPremiumRatingRegionHouseholdMemberFive +
    silverPremiumRatingRegionHouseholdMemberSix +
    silverPremiumRatingRegionHouseholdMemberSeven;
  // Get Rating Region annual premium total (Silver Benchmark)
  let silverPremiumRatingRegionAnnualPremium = (silverPremiumRatingRegionMonthlyPremium * 12);

  // Get Rating Region monthly premium total (LC Silver)
  let lcSilverRatingRegionMonthlyPremium =
    lcSilverRatingRegionHouseholdMemberOne +
    lcSilverRatingRegionHouseholdMemberTwo +
    lcSilverRatingRegionHouseholdMemberThree +
    lcSilverRatingRegionHouseholdMemberFour +
    lcSilverRatingRegionHouseholdMemberFive +
    lcSilverRatingRegionHouseholdMemberSix +
    lcSilverRatingRegionHouseholdMemberSeven;
  // Get Rating Region annual premium total (LC Silver)
  let lcSilverRatingRegionAnnualPremium = (lcSilverRatingRegionMonthlyPremium * 12);

  let filteredFPLData = fplData
      .filter((item) => item.State == "Default")

  if (zipCodeStateAbbrev == "HI") {
    filteredFPLData = fplData
      .filter((item) => item.State == "HI")

  } if (zipCodeStateAbbrev == "AK") {
    filteredFPLData = fplData
        .filter((item) => item.State == "AK")
  }

  let fplDenominator = filteredFPLData
      .filter((item) => item.HouseholdSize == householdMemberCount)
      .map((filteredItem) => filteredItem.CurrentYear);

  let fpl = Math.floor((householdIncome / fplDenominator) * 100) || 0;

  if (fpl === Infinity) {
    fpl = "INF"
  }

  // Get ACA Percentage
  let acaInitialValue = aptcSubsidyData
  .filter((item) => item.FPL == fpl)
  .map((filteredItem) => filteredItem.ACA);
  let acaPercent = (acaInitialValue[0] * 100);
  // Show this value below, because it's rounded to 2 decimal places
  let acaPercentDisplayed = acaPercent.toFixed(2) || 0;

  if (acaPercentDisplayed === Infinity || acaPercentDisplayed >= 1000) {
    acaPercentDisplayed = "999"
  }

  // Get ARPA Percentage
  let arpaInitialValue = aptcSubsidyData
  .filter((item) => item.FPL == fpl)
  .map((filteredItem) => filteredItem.ARPA);
  let arpaPercent = (arpaInitialValue[0] * 100);
  // Show this value below, because it's rounded to 2 decimal places
  let arpaPercentDisplayed = arpaPercent.toFixed(2) || 0;

  if (arpaPercentDisplayed === Infinity || arpaPercentDisplayed >= 1000) {
    arpaPercentDisplayed = "999"
  }

  // Get ACA Monthly Premium Cap
  let acaMonthlyPremiumCap = (acaInitialValue[0] * (householdIncome / 12));
  let acaMonthlyPremiumCapRounded = Math.round(acaMonthlyPremiumCap);

  // Get ARPA Monthly Premium Cap
  let arpaMonthlyPremiumCap = (arpaInitialValue[0] * (householdIncome / 12));
  let arpaMonthlyPremiumCapRounded = Math.round(arpaMonthlyPremiumCap);

  // Get ACA Monthly Premium Cap dollar difference
  let acaMonthlyPremiumCapDollarDiff = (arpaMonthlyPremiumCap - acaMonthlyPremiumCap);
  let acaMonthlyPremiumCapDollarDiffRounded = Math.round(acaMonthlyPremiumCapDollarDiff);

  // Get savings to ACA percent difference
  let savingsAcaDiffPercent = ((arpaInitialValue[0] / acaInitialValue[0]) - 1);
  let savingsAcaDiffPercentDisplayed = (savingsAcaDiffPercent * 100) || 0;
  let savingsAcaDiffPercentDisplayedRounded = Math.round(savingsAcaDiffPercentDisplayed)

  if (savingsAcaDiffPercentDisplayedRounded === Infinity || savingsAcaDiffPercentDisplayedRounded >= 1000) {
    savingsAcaDiffPercentDisplayedRounded = "999"
  }

  // Get increase without ARPA percent
  let increaseNoArpaDiffPercent = ((acaInitialValue[0] / arpaInitialValue[0]) - 1);
  let increaseNoArpaDiffPercentDisplayed = (increaseNoArpaDiffPercent * 100) || 0;
  let increaseNoArpaDiffPercentDisplayedRounded = Math.round(increaseNoArpaDiffPercentDisplayed);

  if (increaseNoArpaDiffPercentDisplayedRounded === Infinity || increaseNoArpaDiffPercentDisplayedRounded >= 1000) {
    increaseNoArpaDiffPercentDisplayedRounded = "999"
  }

  // Additional table calculations
  let acaMonthlyPremiumCapPercentDiff = ((arpaMonthlyPremiumCap / acaMonthlyPremiumCap) - 1)
  let acaMonthlyPremiumCapPercentDiffDisplayed = (acaMonthlyPremiumCapPercentDiff * 100) || 0;
  let acaMonthlyPremiumCapPercentDiffDisplayedRounded = Math.round(acaMonthlyPremiumCapPercentDiffDisplayed);

  if (acaMonthlyPremiumCapPercentDiffDisplayedRounded === Infinity || acaMonthlyPremiumCapPercentDiffDisplayedRounded >= 1000) {
    acaMonthlyPremiumCapPercentDiffDisplayedRounded = "999"
  }

  let incWithoutArpaPremiumMonthlyCap = -(acaMonthlyPremiumCapDollarDiff)
  let incWithoutArpaPremiumMonthlyCapRounded = Math.round(incWithoutArpaPremiumMonthlyCap);
  let incWithoutArpaPremiumMonthlyCapPercent = ((acaMonthlyPremiumCap / arpaMonthlyPremiumCap) - 1)
  let incWithoutArpaPremiumMonthlyCapPercentDisplayed = (incWithoutArpaPremiumMonthlyCapPercent * 100) || 0;
  let incWithoutArpaPremiumMonthlyCapPercentDisplayedRounded = Math.round(incWithoutArpaPremiumMonthlyCapPercentDisplayed);

  if (incWithoutArpaPremiumMonthlyCapPercentDisplayedRounded === Infinity || incWithoutArpaPremiumMonthlyCapPercentDisplayedRounded >= 1000) {
    incWithoutArpaPremiumMonthlyCapPercentDisplayedRounded = "999"
  }

  if (districtOrRegion == "RatingRegion") {
    acaSubsidyAmountInitial = (silverPremiumRatingRegionMonthlyPremium - acaMonthlyPremiumCap)
  }

  let acaSubsidyAmount = Math.max(0, acaSubsidyAmountInitial)
  let acaSubsidyAmountRounded = Math.round(acaSubsidyAmount);

  if (districtOrRegion == "RatingRegion") {
    arpaSubsidyAmountInitial = (silverPremiumRatingRegionMonthlyPremium - arpaMonthlyPremiumCap)
  }

  let arpaSubsidyAmount = Math.max(0, arpaSubsidyAmountInitial)

  if (fpl <= 400) {
    arpaSubsidyAmountRounded = Math.round(arpaSubsidyAmount);
  }

  let subsidyAcaSavingsAmount = (arpaSubsidyAmount - acaSubsidyAmount);
  let subsidyAcaSavingsAmountRounded = Math.round(subsidyAcaSavingsAmount);
  let subsidyAcaSavingsPercent = ((arpaSubsidyAmount / acaSubsidyAmount) - 1);
  let subsidyAcaSavingsPercentDisplayed = (subsidyAcaSavingsPercent * 100) || 0;
  let subsidyAcaSavingsPercentDisplayedRounded = Math.round(subsidyAcaSavingsPercentDisplayed);

  if (subsidyAcaSavingsPercentDisplayedRounded === Infinity || subsidyAcaSavingsPercentDisplayedRounded >= 1000) {
    subsidyAcaSavingsPercentDisplayedRounded = "999"
  }

  let incWithoutArpaSubsidyDiffAmount = -(subsidyAcaSavingsAmount)
  let incWithoutArpaSubsidyDiffAmountRounded = Math.round(incWithoutArpaSubsidyDiffAmount);
  let incWithoutArpaSubsidyDiffPercent = ((acaSubsidyAmount / arpaSubsidyAmount) - 1)
  let incWithoutArpaSubsidyDiffPercentDisplayed = (incWithoutArpaSubsidyDiffPercent * 100) || 0;
  let incWithoutArpaSubsidyDiffPercentDisplayedRounded = Math.round(incWithoutArpaSubsidyDiffPercentDisplayed);

  if (incWithoutArpaSubsidyDiffPercentDisplayedRounded === Infinity || incWithoutArpaSubsidyDiffPercentDisplayedRounded >= 1000) {
    incWithoutArpaSubsidyDiffPercentDisplayedRounded = "999"
  }

  if (districtOrRegion == "RatingRegion") {
    acaMonthlyNetPremiumLcSilverInitial = (lcSilverRatingRegionMonthlyPremium - acaSubsidyAmount)
  }

  let acaMonthlyNetPremiumLcSilver = Math.max(0, acaMonthlyNetPremiumLcSilverInitial)
  let acaMonthlyNetPremiumLcSilverRounded = Math.round(acaMonthlyNetPremiumLcSilver)

  if (districtOrRegion == "RatingRegion") {
    acaMonthlyNetPremiumSilverBenchmarkInitial = (silverPremiumRatingRegionMonthlyPremium - acaSubsidyAmount)
  }

  let acaMonthlyNetPremiumSilverBenchmark = Math.max(0, acaMonthlyNetPremiumSilverBenchmarkInitial)
  let acaMonthlyNetPremiumSilverBenchmarkRounded = Math.round(acaMonthlyNetPremiumSilverBenchmark);

  if (districtOrRegion == "RatingRegion") {
    arpaMonthlyNetPremiumLcSilverInitial = (lcSilverRatingRegionMonthlyPremium - arpaSubsidyAmount)
  }

  let arpaMonthlyNetPremiumLcSilver = Math.max(0, arpaMonthlyNetPremiumLcSilverInitial)
  let arpaMonthlyNetPremiumLcSilverRounded = Math.round(arpaMonthlyNetPremiumLcSilver);

  if (districtOrRegion == "RatingRegion") {
    arpaMonthlyNetPremiumSilverBenchmarkInitial = (silverPremiumRatingRegionMonthlyPremium - arpaSubsidyAmount)
  }

  if (zipCodeStateAbbrev !== "IL" || zipCodeStateAbbrev !== "OR") {
    arpaMonthlyNetPremiumSilverBenchmark = Math.max(0,arpaMonthlyNetPremiumSilverBenchmarkInitial)
  }

  // Minimum monthly premium must be $1 for Illinois or Oregon
  if (zipCodeStateAbbrev == "IL" || zipCodeStateAbbrev == "OR") {
    arpaMonthlyNetPremiumSilverBenchmark = (Math.max(0,arpaMonthlyNetPremiumSilverBenchmarkInitial)) || 1
  }

  if (fpl <= 400) {
    arpaMonthlyNetPremiumSilverBenchmarkRounded = Math.round(arpaMonthlyNetPremiumSilverBenchmark);
  }

  let monthlyAcaSavingsDiffAmountLcSilver = (arpaMonthlyNetPremiumLcSilver - acaMonthlyNetPremiumLcSilver)
  let monthlyAcaSavingsDiffAmountLcSilverRounded = Math.round(monthlyAcaSavingsDiffAmountLcSilver)
  let monthlyAcaSavingsDiffAmountSilverBenchmark = (arpaMonthlyNetPremiumSilverBenchmark - acaMonthlyNetPremiumSilverBenchmark)
  let monthlyAcaSavingsDiffAmountSilverBenchmarkRounded = Math.round(monthlyAcaSavingsDiffAmountSilverBenchmark);

  let monthlyAcaSavingsDiffPercentLcSilver = ((arpaMonthlyNetPremiumLcSilver / acaMonthlyNetPremiumLcSilver) - 1)
  let monthlyAcaSavingsDiffPercentLcSilverDisplayed = (monthlyAcaSavingsDiffPercentLcSilver * 100) || 0;
  let monthlyAcaSavingsDiffPercentLcSilverDisplayedRounded = Math.round(monthlyAcaSavingsDiffPercentLcSilverDisplayed);
  let monthlyAcaSavingsDiffPercentSilverBenchmark = ((arpaMonthlyNetPremiumSilverBenchmark / acaMonthlyNetPremiumSilverBenchmark) - 1)
  let monthlyAcaSavingsDiffPercentSilverBenchmarkDisplayed = (monthlyAcaSavingsDiffPercentSilverBenchmark * 100) || 0;
  let monthlyAcaSavingsDiffPercentSilverBenchmarkDisplayedRounded = Math.round(monthlyAcaSavingsDiffPercentSilverBenchmarkDisplayed);

  if (monthlyAcaSavingsDiffPercentLcSilverDisplayedRounded === Infinity || monthlyAcaSavingsDiffPercentLcSilverDisplayedRounded >= 1000) {
    monthlyAcaSavingsDiffPercentLcSilverDisplayedRounded = "999"
  }

  if (monthlyAcaSavingsDiffPercentSilverBenchmarkDisplayedRounded === Infinity || monthlyAcaSavingsDiffPercentSilverBenchmarkDisplayedRounded >= 1000) {
    monthlyAcaSavingsDiffPercentSilverBenchmarkDisplayedRounded = "999"
  }

  let incWithoutArpaMonthlyDiffAmountLcSilver = -(monthlyAcaSavingsDiffAmountLcSilver)
  let incWithoutArpaMonthlyDiffAmountLcSilverRounded = Math.round(incWithoutArpaMonthlyDiffAmountLcSilver)
  let incWithoutArpaMonthlyDiffAmountSilverBenchmark = -(monthlyAcaSavingsDiffAmountSilverBenchmark)
  let incWithoutArpaMonthlyDiffAmountSilverBenchmarkRounded = Math.round(incWithoutArpaMonthlyDiffAmountSilverBenchmark)

  let incWithoutArpaMonthlyDiffPercentLcSilver = ((acaMonthlyNetPremiumLcSilver / arpaMonthlyNetPremiumLcSilver) - 1)
  let incWithoutArpaMonthlyDiffPercentLcSilverDisplayed = (incWithoutArpaMonthlyDiffPercentLcSilver * 100) || 0;
  let incWithoutArpaMonthlyDiffPercentLcSilverDisplayedRounded = Math.round(incWithoutArpaMonthlyDiffPercentLcSilverDisplayed);
  let incWithoutArpaMonthlyDiffPercentSilverBenchmark = ((acaMonthlyNetPremiumSilverBenchmark / arpaMonthlyNetPremiumSilverBenchmark) - 1)
  let incWithoutArpaMonthlyDiffPercentSilverBenchmarkDisplayed = (incWithoutArpaMonthlyDiffPercentSilverBenchmark * 100) || 0;
  let incWithoutArpaMonthlyDiffPercentSilverBenchmarkDisplayedRounded = Math.round(incWithoutArpaMonthlyDiffPercentSilverBenchmarkDisplayed);

  if (incWithoutArpaMonthlyDiffPercentLcSilverDisplayedRounded === Infinity || incWithoutArpaMonthlyDiffPercentLcSilverDisplayedRounded >= 1000) {
    incWithoutArpaMonthlyDiffPercentLcSilverDisplayedRounded = "999"
  }

  if (incWithoutArpaMonthlyDiffPercentSilverBenchmarkDisplayedRounded === Infinity || incWithoutArpaMonthlyDiffPercentSilverBenchmarkDisplayedRounded >= 1000) {
    incWithoutArpaMonthlyDiffPercentSilverBenchmarkDisplayedRounded = "999"
  }

  let acaAnnualPremiumCap = (acaInitialValue[0] * householdIncome);
  let acaAnnualPremiumCapRounded = Math.round(acaAnnualPremiumCap)
  let arpaAnnualPremiumCap = (arpaInitialValue[0] * householdIncome)
  let arpaAnnualPremiumCapRounded = Math.round(arpaAnnualPremiumCap);
  let acaAnnualPremiumCapDollarDiff = (arpaAnnualPremiumCap - acaAnnualPremiumCap);
  let acaAnnualPremiumCapDollarDiffRounded = Math.round(acaAnnualPremiumCapDollarDiff);
  let annualAcaSavingsDiffPercent = ((arpaAnnualPremiumCap / acaAnnualPremiumCap) - 1)
  let annualAcaSavingsDiffPercentDisplayed = (annualAcaSavingsDiffPercent * 100) || 0;
  let annualAcaSavingsDiffPercentDisplayedRounded = Math.round(annualAcaSavingsDiffPercentDisplayed);
  let incWithoutArpaAnnualDiffAmount = -(acaAnnualPremiumCapDollarDiff)
  let incWithoutArpaAnnualDiffAmountRounded = Math.round(incWithoutArpaAnnualDiffAmount);
  let annualIncWithoutArpaPremiumCapPercent = ((acaAnnualPremiumCap / arpaAnnualPremiumCap) - 1)
  let annualIncWithoutArpaPremiumCapPercentDisplayed = (annualIncWithoutArpaPremiumCapPercent * 100) || 0;
  let annualIncWithoutArpaPremiumCapPercentDisplayedRounded = Math.round(annualIncWithoutArpaPremiumCapPercentDisplayed);

  if (annualAcaSavingsDiffPercentDisplayedRounded === Infinity || annualAcaSavingsDiffPercentDisplayedRounded >= 1000) {
    annualAcaSavingsDiffPercentDisplayedRounded = "999"
  }

  if (annualIncWithoutArpaPremiumCapPercentDisplayedRounded === Infinity || annualIncWithoutArpaPremiumCapPercentDisplayedRounded >= 1000) {
    annualIncWithoutArpaPremiumCapPercentDisplayedRounded = "999"
  }

  if (districtOrRegion == "RatingRegion") {
    annualSubsidyAcaInitial = (silverPremiumRatingRegionAnnualPremium - acaAnnualPremiumCap)
  }

  let annualSubsidyAca = Math.max(0, annualSubsidyAcaInitial)
  let annualSubsidyAcaRounded = Math.round(annualSubsidyAca)

  if (districtOrRegion == "RatingRegion") {
    annualSubsidyArpaInitial = (silverPremiumRatingRegionAnnualPremium - arpaAnnualPremiumCap)
  }

  let annualSubsidyArpa = Math.max(0, annualSubsidyArpaInitial)
  let annualSubsidyArpaRounded = Math.round(annualSubsidyArpa)

  let annualSubsidySavingsAcaDiffAmount = (annualSubsidyArpa - annualSubsidyAca)
  let annualSubsidySavingsAcaDiffAmountRounded = Math.round(annualSubsidySavingsAcaDiffAmount);
  let annualSubsidySavingsAcaDiffPercent = ((annualSubsidyArpa / annualSubsidyAca) - 1)
  let annualSubsidySavingsAcaDiffPercentDisplayed = (annualSubsidySavingsAcaDiffPercent * 100) || 0;
  let annualSubsidySavingsAcaDiffPercentDisplayedRounded = Math.round(annualSubsidySavingsAcaDiffPercentDisplayed);
  let annualSubsidyIncWithoutArapDiffAmount = -(annualSubsidySavingsAcaDiffAmount)
  let annualSubsidyIncWithoutArapDiffAmountRounded = Math.round(annualSubsidyIncWithoutArapDiffAmount);
  let annualSubsidyIncWithoutArpaPercent = ((annualSubsidyAca / annualSubsidyArpa) - 1)
  let annualSubsidyIncWithoutArpaPercentDisplayed = (annualSubsidyIncWithoutArpaPercent * 100) || 0;
  let annualSubsidyIncWithoutArpaPercentDisplayedRounded = Math.round(annualSubsidyIncWithoutArpaPercentDisplayed);

  if (annualSubsidySavingsAcaDiffPercentDisplayedRounded === Infinity || annualSubsidySavingsAcaDiffPercentDisplayedRounded >= 1000) {
    annualSubsidySavingsAcaDiffPercentDisplayedRounded = "999"
  }

  if (annualSubsidyIncWithoutArpaPercentDisplayedRounded === Infinity || annualSubsidyIncWithoutArpaPercentDisplayedRounded >= 1000) {
    annualSubsidyIncWithoutArpaPercentDisplayedRounded = "999"
  }

  let acaAnnualNetPremiumLcSilver = (acaMonthlyNetPremiumLcSilver * 12)
  let acaAnnualNetPremiumLcSilverRounded = Math.round(acaAnnualNetPremiumLcSilver)
  let acaAnnualNetPremiumSilverBenchmark = (acaMonthlyNetPremiumSilverBenchmark * 12)
  let acaAnnualNetPremiumSilverBenchmarkRounded = Math.round(acaAnnualNetPremiumSilverBenchmark);

  let arpaAnnualNetPremiumLcSilver = (arpaMonthlyNetPremiumLcSilver * 12)
  let arpaAnnualNetPremiumLcSilverRounded = Math.round(arpaAnnualNetPremiumLcSilver)
  let arpaAnnualNetPremiumSilverBenchmark = (arpaMonthlyNetPremiumSilverBenchmark * 12)
  let arpaAnnualNetPremiumSilverBenchmarkRounded = Math.round(arpaAnnualNetPremiumSilverBenchmark)

  let annualSavingsAcaDiffAmountLcSilver = (arpaAnnualNetPremiumLcSilver - acaAnnualNetPremiumLcSilver)
  let annualSavingsAcaDiffAmountLcSilverRounded = Math.round(annualSavingsAcaDiffAmountLcSilver);
  let annualSavingsAcaDiffAmountSilverBenchmark = (arpaAnnualNetPremiumSilverBenchmark - acaAnnualNetPremiumSilverBenchmark)
  let annualSavingsAcaDiffAmountSilverBenchmarkRounded = Math.round(annualSavingsAcaDiffAmountSilverBenchmark);

  let annualSavingsAcaDiffPercentLcSilver = ((arpaAnnualNetPremiumLcSilver / acaAnnualNetPremiumLcSilver) - 1)
  let annualSavingsAcaDiffPercentLcSilverDisplayed = (annualSavingsAcaDiffPercentLcSilver * 100) || 0;
  let annualSavingsAcaDiffPercentLcSilverDisplayedRounded = Math.round(annualSavingsAcaDiffPercentLcSilverDisplayed);
  let annualSavingsAcaDiffPercentSilverBenchmark = ((arpaAnnualNetPremiumSilverBenchmark / acaAnnualNetPremiumSilverBenchmark) - 1)
  let annualSavingsAcaDiffPercentSilverBenchmarkDisplayed = (annualSavingsAcaDiffPercentSilverBenchmark * 100) || 0;
  let annualSavingsAcaDiffPercentSilverBenchmarkDisplayedRounded = Math.round(annualSavingsAcaDiffPercentSilverBenchmarkDisplayed);

  if (annualSavingsAcaDiffPercentLcSilverDisplayedRounded === Infinity || annualSavingsAcaDiffPercentLcSilverDisplayedRounded >= 1000) {
    annualSavingsAcaDiffPercentLcSilverDisplayedRounded = "999"
  }

  if (annualSavingsAcaDiffPercentSilverBenchmarkDisplayedRounded === Infinity || annualSavingsAcaDiffPercentSilverBenchmarkDisplayedRounded >= 1000) {
    annualSavingsAcaDiffPercentSilverBenchmarkDisplayedRounded = "999"
  }

  let incWithoutArpaAnnualDiffAmountLcSilver = -(annualSavingsAcaDiffAmountLcSilver)
  let incWithoutArpaAnnualDiffAmountLcSilverRounded = Math.round(incWithoutArpaAnnualDiffAmountLcSilver);
  let incWithoutArpaAnnualDiffAmountSilverBenchmark = -(annualSavingsAcaDiffAmountSilverBenchmark)
  let incWithoutArpaAnnualDiffAmountSilverBenchmarkRounded = Math.round(incWithoutArpaAnnualDiffAmountSilverBenchmark);

  let annualIncWithoutArpaDiffPercentLcSilver = ((acaAnnualNetPremiumLcSilver / arpaAnnualNetPremiumLcSilver) - 1)
  let annualIncWithoutArpaDiffPercentLcSilverDisplayed = (annualIncWithoutArpaDiffPercentLcSilver * 100) || 0;
  let annualIncWithoutArpaDiffPercentLcSilverDisplayedRounded = Math.round(annualIncWithoutArpaDiffPercentLcSilverDisplayed);
  let annualIncWithoutArpaDiffPercentSilverBenchmark = ((acaAnnualNetPremiumSilverBenchmark / arpaAnnualNetPremiumSilverBenchmark) - 1)
  let annualIncWithoutArpaDiffPercentSilverBenchmarkDisplayed = (annualIncWithoutArpaDiffPercentSilverBenchmark * 100) || 0;
  let annualIncWithoutArpaDiffPercentSilverBenchmarkDisplayedRounded = Math.round(annualIncWithoutArpaDiffPercentSilverBenchmarkDisplayed);

  if (annualIncWithoutArpaDiffPercentLcSilverDisplayedRounded === Infinity || annualIncWithoutArpaDiffPercentLcSilverDisplayedRounded >= 1000) {
    annualIncWithoutArpaDiffPercentLcSilverDisplayedRounded = "999"
  }

  if (annualIncWithoutArpaDiffPercentSilverBenchmarkDisplayedRounded === Infinity || annualIncWithoutArpaDiffPercentSilverBenchmarkDisplayedRounded >= 1000) {
    annualIncWithoutArpaDiffPercentSilverBenchmarkDisplayedRounded = "999"
  }

  if (districtOrRegion == "RatingRegion") {
    silverBenchmarkMonthlyPremium = silverPremiumRatingRegionMonthlyPremium
  }

  let silverBenchmarkMonthlyPremiumRounded = Math.round(silverBenchmarkMonthlyPremium)


  // Handle household income value change
  function handleHouseholdIncome(e) {
    setHouseholdIncomeString(e.target.value)
    setHouseholdIncome(e.target.value.replaceAll(",", ""));
  }

  // Handle user inputted age values
  function handleAgeMemberOne(e) {
    setAgeMemberOne(e.target.value);
  }
  function handleAgeMemberTwo(e) {
    setAgeMemberTwo(e.target.value);
  }
  function handleAgeMemberThree(e) {
    setAgeMemberThree(e.target.value);
  }
  function handleAgeMemberFour(e) {
    setAgeMemberFour(e.target.value);
  }
  function handleAgeMemberFive(e) {
    setAgeMemberFive(e.target.value);
  }
  function handleAgeMemberSix(e) {
    setAgeMemberSix(e.target.value);
  }
  function handleAgeMemberSeven(e) {
    setAgeMemberSeven(e.target.value);
  }

  // Show/hide comma on Zip Code input
   useEffect(() => {
    if (zipCode.length == 5) {
      setShowZipcodeComma(true)
    }
  });

  useEffect(() => {
    if (zipCode.length !== 5) {
      setShowZipcodeComma(false)
    }
  });

  // Form validation
  useEffect(() => {
    if (zipCode.length == 5 && districtOrRegion.length > 0 && householdIncome.length > 0 && fpl >= 139) {
      setShowCalcResultsButton(true)
      setHideCalcResultsButton(false)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (zipCode.length == 5 && districtOrRegion.length > 0 && householdIncome.length > 0 && isMedicaidExpanded === "Y" && (fpl >= 100 && fpl < 139)) {
      setShowCalcResultsButton(false)
      setHideCalcResultsButton(true)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });
  
  useEffect(() => {
    if (zipCode.length == 5 && districtOrRegion.length > 0 && householdIncome.length > 0 && isMedicaidExpanded === "N" && (fpl >= 100 && fpl < 139)) {
      setShowCalcResultsButton(true)
      setHideCalcResultsButton(false)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (zipCode.length == 5 && districtOrRegion.length > 0 && householdIncome.length > 0 && isMedicaidExpanded === "N" && (fpl >= 100 && fpl < 139)) {
      setShowCalcResultsButton(true)
      setHideCalcResultsButton(false)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (zipCode.length != 5 || districtOrRegion.length < 1 || householdIncome.length < 1 || fpl < 100) {
      setShowCalcResultsButton(false)
      setHideCalcResultsButton(true)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (householdIncome.length > 0 && isMedicaidExpanded === "Y" && (fpl >= 100 && fpl < 139)) {
      setShowFplExclusionNote(true)
      setShowMedicaidExclusionNote(false)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (householdIncome.length > 0 && isMedicaidExpanded === "Y" && fpl < 100) {
      setShowFplExclusionNote(true)
      setShowMedicaidExclusionNote(false)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (householdIncome.length > 0 && fpl >= 139) {
      setShowFplExclusionNote(false)
      setShowMedicaidExclusionNote(false)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (householdIncome.length > 0 && isMedicaidExpanded === "Y" && fpl < 100) {
      setShowMedicaidExclusionNote(false)
      setShowFplExclusionNote(true)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (householdIncome.length > 0 && isMedicaidExpanded === "N" && fpl < 100) {
      setShowMedicaidExclusionNote(true)
      setShowFplExclusionNote(false)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (householdIncome.length > 0 && isMedicaidExpanded === "N" && fpl >= 100) {
      setShowMedicaidExclusionNote(false)
      setShowFplExclusionNote(false)
      setShowFplMNNote(false)
      setShowFplNYNote(false)
    }
  });

  useEffect(() => {
    if (householdIncome.length > 0 && zipCodeStateAbbrev === 'NY'  && fpl >= 139 && fpl <= 199) {
      setShowMedicaidExclusionNote(false)
      setShowFplExclusionNote(false)
      setShowFplNYNote(true)
      setShowCalcResultsButton(false)
      setHideCalcResultsButton(true)
    }
  });

  useEffect(() => {
    if (householdIncome.length > 0 && zipCodeStateAbbrev === 'MN'  && (fpl >= 139 && fpl <= 199)) {
      setShowMedicaidExclusionNote(false)
      setShowFplExclusionNote(false)
      setShowFplMNNote(true)
      setShowCalcResultsButton(false)
      setHideCalcResultsButton(true)
    }
  });

  // Ensure details element opens if/when user prints results
  const printing = useDetectPrint();

  const printResults = () => {
    document.querySelector(".calculator__results-table").open = true;
    window.print();  
  }

  // Premium Cap calculations for high FPL families
  let premiumCapMonthly = (.085 * (householdIncome/12));

  let premiumCapMonthlyDifference = (silverPremiumRatingRegionMonthlyPremium - premiumCapMonthly)

  let premiumCapMonthlyDifferenceRounded = Math.round(premiumCapMonthlyDifference)

  let premiumCapMonthlyDifferenceRoundedMax = Math.max(premiumCapMonthlyDifferenceRounded, 0)

  let minHighFplPremium = Math.min(premiumCapMonthly, silverPremiumRatingRegionMonthlyPremium)

  let minHighFplPremiumRounded = Math.round(minHighFplPremium)  

  if (fpl > 400) {
    arpaMonthlyNetPremiumSilverBenchmarkRounded = minHighFplPremiumRounded;
  }

  if (fpl > 400) {
    arpaSubsidyAmountRounded = premiumCapMonthlyDifferenceRoundedMax;
  }

  // console.log('Premium Cap Monthly:' + premiumCapMonthly)

  // console.log('Monthly Premium:' + silverPremiumRatingRegionMonthlyPremium)

  // console.log('Cap difference' + premiumCapMonthlyDifference)

  // console.log('Min High FPL Premium Rounded: ' + minHighFplPremiumRounded)

  // console.log('Monthly high fpl Diff rounded: ' + arpaSubsidyAmountRounded)

  return (
    <StyledEngineProvider injectFirst>
      <Helmet>
        <title>Health Insurance Marketplace Subsidy Calculator</title>
        <meta name="description" content="Health Insurance Marketplace Subsidy Calculator" />
      </Helmet>
      <div className="container" id="topOfPage">
        <div className="inner">
          <Sidebar pageContext="calculator" />

          <section id="calculator" className="calculator">
            {hideCalculator &&
              <Header pageContext="calculator"/>
            }
            <div className="calculator__content">
              {hideCalculator &&
                <div className="calculator__form">
                  <InstructionalBlock />
                  <div className="bold-22" style={{ marginBottom: "2.5rem" }}>
                    Fill out the form to get started:
                  </div>
                  <div className="calculator__form--element">
                    <div className="calculator__form--tooltip">
                      <Tooltip
                        title="Zip code of your residence"
                        placement="top"
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        arrow
                      >
                        <Button>
                          <InfoIcon sx={{ color: "#A8B8DB" }} />
                        </Button>
                      </Tooltip>
                      <span className="calculator__form--tooltip-text">
                        Enter your zip code
                      </span>
                    </div>
                    <div className="calculator__form-field calculator__form-field--zipcode">
                      <TextField
                        value={zipCode}
                        id="input-zipcode"
                        className="calculator__form-field--zipcode-container"
                        placeholder="Zip Code"
                        fullWidth
                        variant="standard"
                        onChange={handleZipCodeInputChange}
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon sx={{ color: "#0a48d7" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div className="calculator__form--details calculator__form--details-zipcode">
                        <span className="calculator__form--details-text">
                          County name, State:
                        </span>{" "}
                        <span className="calculator__form--details-filtered">
                          {zipCodeCounty}{showZipcodeComma == true && (
                            <>,</>
                          )} {zipCodeState}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="calculator__form--elements">
                    {/* Household Income form element */}
                    <div className="calculator__form--element">
                      <div className="calculator__form--tooltip">
                        <Tooltip
                          title="Best estimate of your household income, before taxes, for 2024"
                          placement="top"
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                          arrow
                        >
                          <Button>
                            <InfoIcon sx={{ color: "#A8B8DB" }} />
                          </Button>
                        </Tooltip>
                        <span className="calculator__form--tooltip-text">
                          Annual household income
                        </span>
                      </div>
                      <div className="calculator__form-field calculator__form-field--income">
                        <TextField
                          value={householdIncomeString}
                          onChange={handleHouseholdIncome}
                          name="numberformat"
                          id="input-income"
                          className="calculator__form-field--income-container"
                          placeholder="e.g. 50000"
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Chip
                                  sx={{
                                    backgroundColor: "#0a48d7",
                                    color: "#ffffff",
                                  }}
                                  label="$"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>

                    {/* Household size form element */}
                    <div className="calculator__form--element">
                      <div className="calculator__form--tooltip">
                        <Tooltip
                          title="Number of family members to be covered by the health plan"
                          placement="top"
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                          arrow
                        >
                          <Button>
                            <InfoIcon sx={{ color: "#A8B8DB" }} />
                          </Button>
                        </Tooltip>
                        <span className="calculator__form--tooltip-text">
                          Family household size
                        </span>
                      </div>
                      <div className="calculator__form-field calculator__form-field--size">
                        <TextField
                          value={householdMemberCount}
                          id="input-size"
                          className="calculator__form-field--size-container"
                          placeholder=""
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                                style: { textAlign: "center" },
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <Button
                                  sx={{ backgroundColor: "#0a48d7" }}
                                  variant="contained"
                                  aria-label="reduce"
                                  onClick={householdMemberIncrementMinus}
                                >
                                  <RemoveIcon
                                    sx={{ color: "#ffffff" }}
                                    fontSize="small"
                                  />
                                </Button>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  sx={{ backgroundColor: "#0a48d7" }}
                                  variant="contained"
                                  aria-label="increase"
                                  onClick={householdMemberIncrementPlus}
                                >
                                  <AddIcon
                                    sx={{ color: "#ffffff" }}
                                    fontSize="small"
                                  />
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="calculator__fpl-note roman-13">
                    Household Federal Poverty Level (FPL) is: {fpl}
                  </div>

                  <div className="calculator__form--element household-member-input household-member-input--1">
                    <div className="calculator__form--tooltip">
                      <span className="calculator__form--tooltip-text">
                        Family household member 1 age
                      </span>
                    </div>
                    <div className="calculator__form-field calculator__form-field--age">
                      <TextField
                        value={ageMemberOne}
                        onChange={handleAgeMemberOne}
                        id="input-age-2"
                        className="calculator__form-field--age-container"
                        placeholder=""
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Chip
                                sx={{
                                  backgroundColor: "#0a48d7",
                                  color: "#ffffff",
                                }}
                                label="YEARS"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="calculator__form--element household-member-input household-member-input--2">
                    <div className="calculator__form--tooltip">
                      <span className="calculator__form--tooltip-text">
                        Family household member 2 age
                      </span>
                    </div>
                    <div className="calculator__form-field calculator__form-field--age">
                      <TextField
                        value={ageMemberTwo}
                        onChange={handleAgeMemberTwo}
                        id="input-age-2"
                        className="calculator__form-field--age-container"
                        placeholder=""
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Chip
                                sx={{
                                  backgroundColor: "#0a48d7",
                                  color: "#ffffff",
                                }}
                                label="YEARS"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="calculator__form--element household-member-input household-member-input--3">
                    <div className="calculator__form--tooltip">
                      <span className="calculator__form--tooltip-text">
                        Family household member 3 age
                      </span>
                    </div>
                    <div className="calculator__form-field calculator__form-field--age">
                      <TextField
                        value={ageMemberThree}
                        onChange={handleAgeMemberThree}
                        id="input-age-3"
                        className="calculator__form-field--age-container"
                        placeholder=""
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Chip
                                sx={{
                                  backgroundColor: "#0a48d7",
                                  color: "#ffffff",
                                }}
                                label="YEARS"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="calculator__form--element household-member-input household-member-input--4">
                    <div className="calculator__form--tooltip">
                      <span className="calculator__form--tooltip-text">
                        Family household member 4 age
                      </span>
                    </div>
                    <div className="calculator__form-field calculator__form-field--age">
                      <TextField
                        value={ageMemberFour}
                        onChange={handleAgeMemberFour}
                        id="input-age-4"
                        className="calculator__form-field--age-container"
                        placeholder=""
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Chip
                                sx={{
                                  backgroundColor: "#0a48d7",
                                  color: "#ffffff",
                                }}
                                label="YEARS"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="calculator__form--element household-member-input household-member-input--5">
                    <div className="calculator__form--tooltip">
                      <span className="calculator__form--tooltip-text">
                        Family household member 5 age
                      </span>
                    </div>
                    <div className="calculator__form-field calculator__form-field--age">
                      <TextField
                        value={ageMemberFive}
                        onChange={handleAgeMemberFive}
                        id="input-age-5"
                        className="calculator__form-field--age-container"
                        placeholder=""
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Chip
                                sx={{
                                  backgroundColor: "#0a48d7",
                                  color: "#ffffff",
                                }}
                                label="YEARS"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="calculator__form--element household-member-input household-member-input--6">
                    <div className="calculator__form--tooltip">
                      <span className="calculator__form--tooltip-text">
                        Family household member 6 age
                      </span>
                    </div>
                    <div className="calculator__form-field calculator__form-field--age">
                      <TextField
                          value={ageMemberSix}
                          onChange={handleAgeMemberSix}
                          id="input-age-6"
                          className="calculator__form-field--age-container"
                          placeholder=""
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            endAdornment: (
                                <InputAdornment position="end">
                                  <Chip
                                      sx={{
                                        backgroundColor: "#0a48d7",
                                        color: "#ffffff",
                                      }}
                                      label="YEARS"
                                  />
                                </InputAdornment>
                            ),
                          }}
                      />
                    </div>
                  </div>

                  <div className="calculator__form--element household-member-input household-member-input--7">
                    <div className="calculator__form--tooltip">
                      <span className="calculator__form--tooltip-text">
                        Family household member 7 age
                      </span>
                    </div>
                    <div className="calculator__form-field calculator__form-field--age">
                      <TextField
                          value={ageMemberSeven}
                          onChange={handleAgeMemberSeven}
                          id="input-age-7"
                          className="calculator__form-field--age-container"
                          placeholder=""
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            endAdornment: (
                                <InputAdornment position="end">
                                  <Chip
                                      sx={{
                                        backgroundColor: "#0a48d7",
                                        color: "#ffffff",
                                      }}
                                      label="YEARS"
                                  />
                                </InputAdornment>
                            ),
                          }}
                      />
                    </div>
                  </div>

                  <div className="calculator__results-btn-wrap">
                    {showCalcResultsButton &&
                      <Link
                        className="btn btn--white bold-14 btn--calc-results"
                        onClick={() => {
                          setShowCalculatorResults(prev => !prev)
                          setHideCalculator(prev => !prev)
                        }}
                        to="topOfPage">
                        Calculate Subsidy
                      </Link>
                    }
                    {hideCalcResultsButton &&
                      <>
                        <Tooltip
                          title="All fields are required"
                          placement="top"
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                          arrow
                        >
                          <div
                            to=""
                            className="btn btn--white bold-14 btn--disabled">
                            Calculate Subsidy
                          </div>
                        </Tooltip>
                        {showFplExclusionNote &&
                          <div className="fpl-exclusion-note d-flex danger">
                            <img class="icon" src={IconExclamation} />
                            <div>
                              <p><strong>It looks like you may be eligible for Medicaid.</strong> If you recently lost Medicaid, check out tips to stay covered <a target="_blank" href={"https://www.bcbs.com/the-health-of-america/articles/are-you-losing-medicaid-tips-stay-covered"}>here</a>.</p>
                              <p>In your state, adults with your reported household income are generally eligible for Medicaid and not eligible for Marketplace subsidies. If you are interested, please seek information from your state Medicaid agency on how to apply.</p>
                            </div>
                          </div>
                        }
                        {showMedicaidExclusionNote &&
                          <div className="fpl-exclusion-note d-flex danger">
                            <img className="icon" src={IconExclamation}/>
                            <div>Adults with incomes below 100% of the Federal Poverty Level are not eligible for Marketplace subsidies. If you are interested, please inquire with your state’s Medicaid agency to see if you are eligible for Medicaid coverage.</div>
                          </div>
                        }
                        {showFplNYNote &&
                          <div className="fpl-exclusion-note d-flex danger">
                            <img className="icon" src={IconExclamation}/>
                            <div>Most consumers in New York with incomes below 200% of the Federal Poverty Level but above 138% are eligible for <b>The Essential Plan</b> in New York and not eligible for regular Marketplace coverage. If you are interested, please seek information from the state Department of Health about this program</div>
                          </div>
                        }
                        {showFplMNNote &&
                          <div className="fpl-exclusion-note d-flex danger">
                            <img className="icon" src={IconExclamation}/>
                            <div>Most consumers in Minnesota with incomes below 200% of the Federal Poverty Level but above 138% are eligible for MinnesotaCare and not eligible for regular Marketplace coverage. If you are interested, please seek information from the state Department of Human Services about this program</div>
                          </div>
                        }
                      </>
                    }
                  </div>
                </div>
              }

          {showCalculatorResults &&
            <div className="calculator__results">
              <div className="calculator__results-for-print">
                <p className="roman-12 purple-grey">The cost calculations below reflect persons with the following demographics and selected plan only.</p>
                <p className="condensed-bold-16">Zip Code: {zipCode}</p>
                <p className="condensed-bold-16">County, State: {zipCodeCounty}, {zipCodeState}</p>
                <p className="condensed-bold-16">Household Income: ${householdIncome}</p>
                <p className="condensed-bold-16">Number in Household: {householdMemberCount}</p>
                <p className="condensed-bold-16">Household Federal Poverty Level: {fpl}</p>
              </div>
              <div className="calculator__results-heading">
                <h1 className="ultra-condensed-44">
                  Monthly Savings Available to You
                </h1>
              </div>
              <StatPanel arpaSubsidyAmountRounded={arpaSubsidyAmountRounded} arpaMonthlyNetPremiumRounded={arpaMonthlyNetPremiumSilverBenchmarkRounded} />

              {planfinderUrlOne && (
                <>
                  <div className="planfinder-block">
                    <div class="planfinder-heading condensed-bold-16">
                      Visit your local BCBS plan to see health insurance plan options for you
                    </div>
                    <div class="planfinder-row">

                      {planfinderUrlOne && (
                        <div className="planfinder-card">
                          <div className="planfinder-card__content">
                            <div className="planfinder-card__name">
                              {planNameOne}
                            </div>
                          </div>
                          <div className="planfinder-card__url">
                            <a target="_blank" href={planfinderUrlOne}>Health Insurance Plans Available
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h50.7L169.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L384 141.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H288zM80 64C35.8 64 0 99.8 0 144V400c0 44.2 35.8 80 80 80H336c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h80c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>
                            </a>
                          </div>
                        </div>
                      )}

                      {planfinderUrlTwo && (planNameOne !== planNameTwo) && (
                        <div className="planfinder-card">
                          <div className="planfinder-card__content">
                            <div className="planfinder-card__name">
                              {planNameTwo}
                            </div>
                          </div>
                          <div className="planfinder-card__url">
                            <a target="_blank" href={planfinderUrlTwo}>Health Insurance Plans Available
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h50.7L169.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L384 141.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H288zM80 64C35.8 64 0 99.8 0 144V400c0 44.2 35.8 80 80 80H336c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h80c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>
                            </a>
                          </div>
                        </div>
                      )}

                      {planfinderUrlThree && (planNameOne !== planNameThree) &&(
                        <div className="planfinder-card">
                          <div className="planfinder-card__content">
                            <div className="planfinder-card__name">
                              {planNameThree}
                            </div>
                          </div>
                          <div className="planfinder-card__url">
                            <a target="_blank" href={planfinderUrlThree}>Health Insurance Plans Available
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h50.7L169.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L384 141.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H288zM80 64C35.8 64 0 99.8 0 144V400c0 44.2 35.8 80 80 80H336c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h80c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>
                            </a>
                          </div>
                        </div>
                      )}

                      {planfinderUrlFour && (planNameOne !== planNameThree) && (
                        <div className="planfinder-card">
                          <div className="planfinder-card__content">
                            <div className="planfinder-card__name">
                              {planNameFour}
                            </div>
                          </div>
                          <div className="planfinder-card__url">
                            <a target="_blank" href={planfinderUrlFour}>Health Insurance Plans Available
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h50.7L169.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L384 141.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H288zM80 64C35.8 64 0 99.8 0 144V400c0 44.2 35.8 80 80 80H336c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h80c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>
                            </a>
                          </div>
                        </div>
                      )}

                      {planfinderUrlFive && (planNameOne !== planNameFive) && (
                        <div className="planfinder-card">
                          <div className="planfinder-card__content">
                            <div className="planfinder-card__name">
                              {planNameFive}
                            </div>
                          </div>
                          <div className="planfinder-card__url">
                            <a target="_blank" href={planfinderUrlFive}>Health Insurance Plans Available
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h50.7L169.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L384 141.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H288zM80 64C35.8 64 0 99.8 0 144V400c0 44.2 35.8 80 80 80H336c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h80c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>
                            </a>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </>
              )}
            
            </div>
            }
          </div>
          <Footer />
        </section>
      </div>
    </div>
    </StyledEngineProvider>
  );
}