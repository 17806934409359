import React from "react";
import { Link } from "gatsby";

const DetailsCopy = () => (
  <p className="light-16">
    The American Rescue Plan Act (ARPA), expands eligibility for
    Affordable Care Act (ACA) premium and cost-sharing subsidies,
    increasing financial assistance for people enrolled in marketplace
    health plans. ARPA extends tax credits above the ACA’s original upper
    income limit so that families buying marketplace coverage never need
    pay more than 8.5% of their income, and ARPA enhances tax credits for
    lower income families, reducing the amount they contribute towards
    premiums to zero in many cases. Use this tool to calculate consumers’
    premium savings by where they live.
  </p>
);

export default DetailsCopy;
